<template>
	<!-- <div>
		<el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" :on-preview="handlePreview"
			:on-remove="handleRemove" :before-remove="beforeRemove" :on-exceed="handleExceed">
			<button class="text-primary border-2 font-semibold h-12 px-6 rounded-lg hover:bg-primary hover:text-white
			focus:ring-offset-2 hover:border-primary transition-all">Зураг сонгох</button>
		</el-upload>
	</div> -->
	<el-upload
		:before-upload="handleBefore"
		:action="actionURL"
		:on-error="handleError"
		:on-success="handleSuccess"
		:on-exceed="handleExceed"
		:disabled="false"
		:limit="100"
		:multiple="false"
		:show-file-list="false"
		:accept="dataAccept"

	>
    <a :class="`btn text-white btn-${id>0?'success':'primary'} btn-xs`">{{id>0?'Файл солих':'Файл сонгох'}}</a>
	</el-upload>
</template>

<script>
// import { Upload } from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";

import { request } from "@/api/all";
import { getToken } from "@/libs/util";
import { mapActions } from "vuex";
export default {
	components:{
		// Upload
	},
	props: {
		id: {
			type: Number,
			default: () => 0
		},
		propAccept: {
      type: String,
      default: () => ""
    },
    folderId: {
      type: Number,
      default: () => null
    },
    boxId: {
      type: Number,
      default: () => 0
    },
    objectTypeId: {
      type: Number,
      default: () => 0
    },
    mode: {
      type: Number,
      default: () => 0
    },
    objectId: {
      type: String,
      default: () => "0"
    }
	},
	data() {
		return {
			actionURL: "",
      file: null,
      fileExceedSize: 20480,
      fileExceedMsg:
        "Файл хуулах хамгийн их хэмжээ: 5мб байна.",
      dataAccept: "image/png, image/jpeg, image/jpg, image/bmp, application/pdf",
      baseURL: "https://darkhan.gosmart.mn/mobCore/",

			// fileList: [
			// 	{ name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }, 
			// 	{ name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }
			// ],

			listFlat: [],
			radioSelected: null,
		}
	},

	computed: {
	},

	methods: {
        ...mapActions(["handleLoading"]),
		handleExceed() {
      this.uploadStart = false;
    },
    handleBefore(file) {
        if (file.size / 1024 > this.fileExceedSize) {
          alert(this.fileExceedMsg);
          return false;
        }
		// this.$nuxt.$loading.start();
        this.handleLoading({ isLoading: true });
        this.uploadStart = true;
    },
    handleError(e) {
      console.log('handleError', e);
    },
    handleSuccess(e) {
		// this.$nuxt.$loading.finish();
        
      this.uploadStart = false;
      if (e.code == 200) {
        this.$emit("inpit", e.data.uploadedAttachmentId);
        this.$emit("on-success", e.data);
        this.handleLoading({ isLoading: false });
      } else {
        this.handleLoading({ isLoading: false });
	    //console.log('sanuulga', e.msg);
      }},
        init() {
            this.actionURL = this.baseURL + "core/uploader?boxId=" + this.boxId + "&folderId=-999" + "&objectTypeId=6" + "&objectId=0" + "&mode=0" + "&token=" + getToken();
        },
	},
	mounted() {
		this.init();
	}
};
</script>

