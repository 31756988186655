<template>
  <div class="h-screen w-full flex flex-col md:flex-row relative">
    <div
      class="hidden md:block md:w-1/2 xl:w-2/3 h-full relative"
      v-show="!isMobile"
    >
      <img :src="imgcur" class="h-full w-full object-cover" alt="" />
      <div class="absolute right-10 bottom-6 gap-2 flex">
        <img
          @click="imgcur = s"
          v-for="(s, si) in imglst"
          :key="si"
          :src="s"
          class="w-12 h-10 rounded-lg border-white border cursor-pointer"
        />
      </div>
    </div>
    <div
      class="flex gap-4 items-center absolute w-full md:w-1/2 xl:w-2/3 px-4 py-2 bg-white border-bottom"
    >
      <img src="/darkhan-logo-teever.png" class="h-9" />
    </div>
    <div class="w-full h-full overflow-auto md:w-1/2 xl:w-1/3 relative">
      <div
        class="hidden absolute top-0 left-0 justify-end w-full lg:flex bg-white border-b border-gray-200 text-sm"
        style="padding: 1rem"
      >
        Утас : 7037-3307
      </div>
      <div
        class="h-full w-full pt-[10rem] lg:pt-[14rem] flex items-center justify-center"
      >
        <div class="p-3 md:p-8">
          <div
            class="block md:hidden w-full h-full max-h-lg relative mb-10"
            v-show="!isMobile"
          >
            <img :src="imgcur" class="h-full w-full object-cover" alt="" />
            <div class="absolute right-2 bottom-2 gap-2 flex">
              <img
                @click="imgcur = s"
                v-for="(s, si) in imglst"
                :key="si"
                :src="s"
                class="w-10 h-8 rounded-lg border-white border cursor-pointer"
              />
            </div>
          </div>

          <form @submit.prevent="doSrch">
            <div class="font-medium text-lg mb-2 text-left">
              Төв замын түр зөвшөөрлийн цахим систем
            </div>
            <!-- <div
              class="mb-4 lg:mb-2 text-sm leading-4 text-gray-500 text-justify"
            >
              Та дараах цонхонд өөрийн эзэмшдэг тээврийн хэрэгслийн улсын
              дугаарыг оруулан товчоодоор нэвтэрсэн мэдээллээ харах цахимаар
              төлөх боломжтой.
            </div> -->
            <!-- <div class="flex gap-2 mb-2 md:mb-4">
              <input
                v-model="p1"
                placeholder="...."
                maxlength="4"
                type="tel"
                ref="input1"
                @input="checkInputLength($event, 'input1')"
                :class="`block p-3 w-3/6 text-sm text-gray-900  rounded-lg border-2  ${
                  p1 != null && p1.length == 4
                    ? 'border-primary-400 bg-gray-50'
                    : 'bg-gray-100 border-cyan-300'
                }  shadow-sm focus:ring-gray-500 focus:border-gray-500`"
              />
              <a
                ref="input2"
                :class="`block p-3 w-2/6 text-sm ${
                  p2 == null || p2 == '' ? 'text-gray-100' : 'text-gray-900'
                } bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500`"
                @click="isFocused1 = true"
                >{{ p2 == null || p2 == "" ? "AA" : p2 }}</a
              >
              <a
                class="block p-3 w-2/6 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                @click="isFocused2 = true"
                >{{ p3 == null || p3 == "" ? "A" : p3 }}</a
              >
            </div>
            <button
              type="submit"
              class="py-2 md:py-3 px-5 w-full text-sm font-medium text-center text-white rounded-lg bg-blue-700 sm:w-fit hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600"
            >
              Шалгах
            </button> -->
          </form>
          <div class="flex flex-col gap-2 md:gap-4 mt-10 md:mt-10">
            
            <div
              class="rounded-xl border-2 border-gray-300 hover:border-gray-200 bg-gray-200 p-2"
            >
              <div class="pb-2">
                Шинээр зөвшөөрөл авах бол доорхи холбоос дээр дарж хүслтээ илгээнэ үү
              </div>
              <div class="bottom-0 left-0 mt-2 w-full p-2">
                <div
                  @click="showReqModal()"
                  class="w-full text-center text-md border border-gray-50 rounded-xl bg-white px-2 hover:bg-blue-500 py-2"
                >
                  Хүсэлт илгээх
                </div>
              </div>
            </div>
            <div
              style="padding-bottom: 3.5rem !important"
              class="rounded-xl border-2 border-gray-300 hover:border-gray-200 bg-gray-200 p-2 pb-14 relative"
            >
              <div class="">
                Төв замын түр зөвшөөрлийн хүсэлт шалгах
              </div>
              <div class="absolute bottom-0 left-0 mt-6 w-full p-2">
                <!-- @click="showPdfModal()" -->
                <button @click="reqObj.typeObj.modal= true;"
                  
                  class="w-full border border-gray-50 rounded-xl bg-white p-2 hover:bg-blue-500"
                >
                  Хүсэлт шалгах
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="padding: 1rem"
        class="hidden absolute bottom-0 left-0 justify-center text-md w-full lg:flex bg-white dark:bg-gray-800 z-20 border-t border-gray-200 dark:border-gray-700"
      >
        Хөгжүүлсэн
        <a
          href="https://gosmart.mn"
          style="display: inline-flex"
          target="_blank"
          ><img
            src="https://res.gosmart.mn//media/favicon/fav.png"
            style="height: 14px; margin-top: 2px"
          />
          ГӨҮСМАРТ ТМ ХХК</a
        >
      </div>
    </div>
  </div>


  <el-dialog
    v-model="reqObj.typeObj.modal"
    title="Хүсэлтийн дугаараар шалгах"
    modal
    top="2vh"
    :append-to-body="true"
   style="width: 600px"
    center
  >
    <div class="col-sm-12" style="width: 900px">
      <div style="margin-top: 1rem">
        <el-form>
          <el-form-item>
            <el-input v-model="checkTsk.task" style="width: 200px" >
              </el-input>
              <el-button @click="checkByTaskId()">Шалгах</el-button>
          </el-form-item>
        </el-form>
        <div v-if="checkFile2Rdy" class="d-flex">
          <div class="wd-50p">
            <div class="mg-b-10 bd-l pd-l-10 bg-gray-200">Зөвшөөрөл</div>
            <embed :src="checkFile1" style="width: 100%; height: 55vh"  />
          </div>
          <div class="wd-50p">
            <div class="mg-b-10 mg-l-20 bd-l pd-l-10 bg-gray-200">Маршрут</div>
            <img :src="checkFile2" style="width: 100%" >
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer" v-show="reqObj.typeObj.selectedType == 1">
        <el-button @click="checkRequestId()">Хайх</el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog
    title="Төв замын түр зөвшөөрлийн хүсэлт илгээх"
    v-model="reqObj.modal"
    :fullscreen="isMobile"
    modal
    :lock-scroll="true"
    top="2vh"
    :append-to-body="true"
    width="80%"
    @close="
      reqObj.typeSelected = false;
      reqObj.type = null;
      reqObj.step = 1;
    "
  >
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <el-steps
            style="margin-top: 1rem"
            :active="reqObj.step"
            align-center
            v-show="!isMobile"
          >
            <el-step title="Тээврийн хэрэгсэлт" description=""></el-step>
            <el-step title="Бүрдүүлэх материал" description=""></el-step>
            <el-step title="Төлбөр төлөх" description=""></el-step>
            <el-step title="Зөвшөөрөл хэвлэх" description=""></el-step>
          </el-steps>
          <div v-show="reqObj.step == 1" class="mt-4">
            <el-form
              @submit.prevent="onSubmit"
              ref="myform"
              :model="taskModel"
              :rules="reqObj.stepRequestRule"
              label-width="320px"
              class="myfrm"
              :label-position="isMobile ? 'top' : 'right'"
              width="100%"
            >
            <el-form-item label="Улсын дугаар" required prop="task" >
                <el-input
                  v-model="taskModel.task"
                  style="width: 200px"
                >
                <template slot="prepend">
                    <el-button @click="fetchDataByPLate()">Шалгах</el-button>
                  </template>
                </el-input>
                <el-button @click="fetchDataByPLate()">Шалгах</el-button>
              </el-form-item>
              <el-form-item label="Эзэмшигч" required prop="isRepeat" >
                <div class="d-flex">
                  <div class="mg-r-20">
                    <label role="radio" aria-checked="true" tabindex="0" :class="`el-radio ${taskModel.isRepeat?'is-checked':''}`">
                      <span :class="`el-radio__input ${taskModel.isRepeat?'is-checked':''}`">
                        <span class="el-radio__inner"></span>
                        <input type="radio" aria-hidden="true" tabindex="-1" autocomplete="off" class="el-radio__original" value="1">
                      </span>
                      <span class="el-radio__label">Аж ахуй нэгж</span>
                    </label>
                  </div>
                  <div>
                    <label role="radio" aria-checked="true" tabindex="0" :class="`el-radio ${!taskModel.isRepeat && isXypFetched?'is-checked':''}`">
                      <span :class="`el-radio__input ${!taskModel.isRepeat && isXypFetched?'is-checked':''}`">
                        <span class="el-radio__inner"></span>
                        <input type="radio" aria-hidden="true" tabindex="-1" autocomplete="off" class="el-radio__original" value="1">
                      </span>
                      <span class="el-radio__label">Иргэн</span>
                    </label>
                  </div>
                </div>
              </el-form-item>
              <el-form-item :label="taskModel.isRepeat?'ААНБ -н нэр':'Иргэн'" required prop="taskEn" >
                <el-input
                  v-model="taskModel.taskEn"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
              <el-form-item  required prop="langCode" 
                :label="taskModel.isRepeat?'ААНБ -н регистр':'Иргэний регистр'"
              >
                <el-input
                  v-model="taskModel.langCode"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
              <el-form-item label="Холбоо барих мэдээлэл" required prop="mobile" >
                <el-input v-model="taskModel.mobile" style="width: 200px" ></el-input>
              </el-form-item>
              <el-form-item label="Марк" required prop="st1" >
                <el-input v-model="taskModel.st1" style="width: 200px" ></el-input>
              </el-form-item>
              <el-form-item label="Модел" required prop="st2" >
                <el-input v-model="taskModel.st2" style="width: 200px" ></el-input>
              </el-form-item>
              <el-form-item label="Төрөл" required prop="lat" >
                <el-input v-model="taskModel.lat" style="width: 200px" ></el-input>
              </el-form-item>
              <el-form-item label="Зориулалт" required prop="lng" >
                <el-input v-model="taskModel.lng" style="width: 200px" ></el-input>
              </el-form-item>

              <el-form-item label="Бүх жин" required prop="i1" >
                <el-input v-model="taskModel.i1" style="width: 200px" ></el-input>
              </el-form-item>
              <el-form-item label="Даац" required prop="i2" >
                <el-input v-model="taskModel.i2" style="width: 200px" ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer wd-100p text-right">
              <el-button
                @click="
                  resetForm();
                  reqObj.modal = false;
                "
                type="warning"
                >Болих</el-button
              >
              <el-button @click="onSubmitBefore" type="info">Дараах</el-button>
            </div>
          </div>

          <div v-show="reqObj.step == 2">
            <el-form
              @submit.prevent="onSubmit"
              :model="taskModel"
              :rules="reqObj.stepRequestRule"
              ref="vehicleform"
              :label-position="isMobile ? 'top' : 'right'"
              label-width="320px"
              class="myfrm mg-t-20 mg-b-20"
              width="100%"
            >
            
              <el-form-item label="Төлбөр" style="margin-bottom: 10px" >
                <select v-if="taskModel.isRepeat" class="form-control wd-200" v-model="taskModel.budget">
                  <option :value="25000" label="25,000 ₮  / 1 сар"></option>
                  <option :value="50000" label="50,000 ₮  / 2 сар"></option>
                  <option :value="75000" label="75,000 ₮  / 3 сар"></option>
                  <option :value="100000" label="100,000 ₮  / 4 сар"></option>
                </select>
                <div v-else class="alert alert-success font-bold" role="alert" style="padding-top: 0px; padding-bottom: 0px">
                  {{ taskModel.budget }}
                </div>
              </el-form-item>
              <el-form-item required prop="defaultAttachmentId" :label="taskModel.isRepeat?'Албан бичиг':'Иргэний хүсэлт'" style="margin-bottom: 5px" >
                <upldr :id="taskModel.defaultAttachmentId" @on-success="fileUploaded($event, 10)" ></upldr>
              </el-form-item>

              <el-form-item required prop="secondAttachmentId" class="mg-t-15" label="Т/Х гэрчилгээнийй хуулбар" style="margin-bottom: 5px" >
                <upldr :id="taskModel.secondAttachmentId" @on-success="fileUploaded($event, 20)" ></upldr>
              </el-form-item>

              <el-form-item required prop="thirdAttachmentId" class="mg-t-15" label="Жолооны үнэмлэхний хуулбар" style="margin-bottom: 5px" >
                <upldr :id="taskModel.thirdAttachmentId" @on-success="fileUploaded($event, 30)" ></upldr>
              </el-form-item>

              <el-form-item required prop="fourthAttachmentId" class="mg-t-15" label="Т/Х -н фото зураг" style="margin-bottom: 5px" >
                <upldr :id="taskModel.fourthAttachmentId" @on-success="fileUploaded($event, 40)" ></upldr>
              </el-form-item>
              <el-form-item required prop="fifthAttachmentId" v-if="taskModel.isRepeat" class="mg-t-15" label="ААНБ -н гэрчилгээний хуулбар" style="margin-bottom: 5px" >
                <upldr :id="taskModel.fifthAttachmentId" @on-success="fileUploaded($event, 50)" ></upldr>
              </el-form-item>

              <el-form-item required prop="dueStartDate" class="mg-t-15" label="Зөвшөөрлийн эхлэх хугацаа" style="margin-bottom: 5px" >
                <el-date-picker v-model="taskModel.dueStartDate" type="date" placeholder="Pick a day"> </el-date-picker>
              </el-form-item>
              <el-form-item required prop="seventhAttachmentId" class="mg-t-15" label="Маршрутын зураг" style="margin-bottom: 5px" >
                <upldr :id="taskModel.seventhAttachmentId" @on-success="fileUploaded($event, 70)" ></upldr>
              </el-form-item>
              <el-form-item required prop="taskDesc" class="mg-t-15" label="Маршрутын тайлбар" style="margin-bottom: 5px" >
                <el-input v-model="taskModel.taskDesc" style="width: 600px" type="textarea" :rows="3"></el-input>
              </el-form-item>
              <el-form-item label="Санамжыг уншиж танилцсан" required prop="isTender" >
                <el-checkbox required v-model="taskModel.isTender" @click="showConfirm()" >Санамжтай танилцах</el-checkbox>
                <a role="button"></a>
                <!-- <el-input
                v-model="taskModel.isTender"
                style="width: 200px"
              ></el-input> -->
              </el-form-item>
            </el-form>
            <div
              slot="footer"
              class="dialog-footer wd-100p text-right"
            >
              <!-- <el-button
                @click="
                  resetVehicleForm();
                "
                type="warning"
                >цэвэрлэх</el-button
              > -->
              <el-button @click="reqObj.step--" type="warning"
                >Өмнөх алхам</el-button
              >
              <el-button @click="onSubmit" type="success"
                >Төлбөр төлөх</el-button
              >
            </div>
          </div>
          <div v-show="reqObj.step == 3">
            <!-- {{ reqObj.invoiceModel }} -->
            <div
              v-for="item in reqObj.paymentStep.paymentOptions"
              :key="`po_${item.id}`"
              @click="paymentMethodSelected(item)"
            >
              <!-- {{ item.name }} -->
            </div>
            <!-- {{ reqObj.paymentStep.paymentMethodSelected }} -->
            <div
              v-if="
                reqObj.paymentStep.paymentMethodSelected != null &&
                reqObj.paymentStep.paymentMethodSelected.id == 1
              "
            >
              <!-- {{ reqObj.paymentStep.invoiceQpay }} -->
              <!-- QPAY -->
              <!-- {{isMobile}} -->
              <div
                v-if="!isMobile"
                class="wd-100p text-center"
                style="justify-content: center; margin-top: 2rem"
              >
                <h3>Үнийн дүн нийт төлөх</h3>

                <div>
                  <h2>
                    {{ reqObj.paymentStep.invoiceQpay.budget.toLocaleString() }}
                    ₮
                  </h2>
                </div>
                <div
                  class="wd-100p text-center d-flex"
                  style="justify-content: center"
                >
                  <img
                    :src="`data:image/jpeg;base64,${reqObj.paymentStep.invoiceQpay.json_data.qPay_QRimage}`"
                  />
                </div>
              </div>

              <div v-show="isMobile">
                <div class="wd-100p text-center">
                  <br />
                  <h3>Үнийн дүн нийт төлөх</h3>
                  <h2>
                    {{ reqObj.paymentStep.invoiceQpay.budget.toLocaleString() }}
                    ₮
                  </h2>
                </div>
                <div
                  v-for="bank in reqObj.paymentStep.invoiceQpay.json_data
                    .qPay_deeplink"
                  :key="`dd_${bank.name}`"
                >
                  <a
                    class="col-span-12 sm:col-span-6 lg:col-span-4"
                    target="_blank"
                    :href="bank.link"
                  >
                    <div
                      class="cursor-pointer relative mt-3 p-6 flex flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md shadow-md shadow-gray/20 transition-all hover:shadow-lg hover:shadow-gray/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    >
                      <div
                        class="relative mx-0 flex items-center gap-4 overflow-hidden bg-transparent bg-clip-border pt-0 text-gray-700 shadow-none"
                      >
                        <img
                          :src="bank.logo"
                          alt="Худалдаа хөгжлийн банк"
                          class="relative inline-block h-[40px] w-[40px] object-cover object-center wd-40 ht-40"
                        />
                        <div class="flex w-full flex-col gap-0.5">
                          <div class="flex items-center justify-between">
                            <h6
                              class="block font-sans text-l font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased"
                            >
                              {{ bank.name }}
                            </h6>
                          </div>
                          <p
                            class="block font-sans text-left text-xs font-light leading-relaxed text-blue-gray-900 antialiased"
                          >
                            картаар төлөх
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div slot="footer" class="dialog-footer wd-100p text-right">
              <el-button
                @click="checkInvoicePayed"
                type="primary"
                :style="
                  isMobile ? `position: fixed; bottom: 40px; right: 40px;` : ``
                "
                >Төлбөр шалгах</el-button
              >
              <!-- <el-button @click="getInvoice" type="warning"
                >Төлбөр төлөх</el-button
              > -->
            </div>
          </div>
          <div v-show="reqObj.step == 4">
            <div class="mg-t-10 text-center">
              <a
                :href="reqObj.pdfLink"
                target="_blank"
                class="btn btn-success mg-b-10"
                >Зөвшөөрөл татаж авах</a
              >
              <!--<div class="text-center">
                <h5>И-Баримт</h5>
              </div>
              <div class="containerImg">
                <img
                  width="200px"
                  :src="`data:image/png;base64, ${ebarimtObj.base64}`"
                />
              </div>-->

              <iframe
                :src="reqObj.pdfLink"
                type="application/pdf"
                width="100%"
                height="500px"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
  <el-dialog
    v-model="pdfModal"
    fullscreen
    modal
    title="Монгол улсын нутаг дэвсгэрээр дамжин өнгөрөх олон улсын тээврийн хэрэгсэл"
    :append-to-body="true"
  >
    <div class="container">
      <img src="/st01.jpg" style="width: 100%; max-width: 1200px" />
    </div>
  </el-dialog>
</template>
<script>
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'

import { request } from "@/api/all";
import { mapActions } from "vuex";
import { setToken } from "@/libs/util";
import upldr from "@/views/Index/uploader.vue";
import moment from "moment";

export default {
  components: {
    upldr,
    //     Swiper,
    //   SwiperSlide
  },
  data() {
    const validateNumber = (rule, value, callback) => {
      if (value === '' || value === undefined) {
        callback(new Error('утга оруулна уу'));
      } else if (value <= 0) {
        callback(new Error('0-c их утга оруулна уу'));
      } else {
        callback();
      }
    };
    
    return {
      checkFile1: null,
      checkFile2: null,
      checkFile2Rdy: false,
      checkTsk:{
        task: null,
      },
      isXypFetched: false,
      locTypeIdSelected: [],
      locTypeId1Selected: [],
      isMobile: false,
      imgcur: "",
      imglst: [
        "https://res.gosmart.mn/media/bg/da-1.jpeg",
        "https://res.gosmart.mn/media/bg/da-2.png",
      ],
      p1: null,
      p2: null,
      p3: null,
      isFocused1: false,
      isFocused2: false,
      options: [
        {
          value: "guide",
          label: "Guide",
        },
      ],
      pdfModal: false,
      taskModel: {
        task: "",
        defaultAttachmentId: null,
        //secondAttachmentId: null,
        //thirdAttachmentId: null,
        //fourthAttachmentId: null,
        //fifthAttachmentId: null,
        phone: null,
        mobile: null,
        d1: null,
      },
      bjMin: 0,
      bjMax: 0,
      vehicleModel: {
        task: "",
        taskEn: "",
        defaultAttachmentId: null,
        secondAttachmentId: null,
        thirdAttachmentId: null,
        fourthAttachmentId: null,
        fifthAttachmentId: null,
        phone: null,
        mobile: null,
        d1: null,
      },
      reqObj: {
        typeObj: {
          modal: false,
          selectedType: null,
          parentRequestObj: {
            id: null,
            task: null,
            defaultAttachmentId: null,
            secondAttachmentId: null,
            i3: null,
            st2: null,

            d1: null,
            d2: null,
            mobile: null,
            locTypeId: null,
            locTypeId1: null,
            fl1: null,
          },
        },
        vehicleFormShow: false,
        step: 1,
        //step: 2,
        requestTaskObj: {},
        vehicles: [],
        invoiceTaskObj: {},
        invoiceModel: {
          invoiceId: 3176483,
        },
        paymentStep: {
          invoiceQpay: {},
          paymentMethodSelected: null,
          paymentOptions: [
            {
              id: 1,
              name: "QPAY",
            },
            // {
            //   id: 2,
            //   name: "Social Pay",
            // },
          ],
        },
        modal: false,
        requestId: 3176479,
        vehicleBoxId: null,
        pdfLink: "",
        places: [],
        placesFlat: [],
        chirguulTypes: [],
        stepRequestRule: {
          task: [
            {
              required: true,
              message:
                "улсын дугаар оруулна уу",
              trigger: "blur",
            },
          ],
          isRepeat: [
            {
              required: true,
              message: "Эзэмшигчийн хэлбэр сонгоно уу",
              trigger: "blur",
            },
          ],
          taskEn: [{ required: true, message: "Нэр бичнэ үү", trigger: "blur"}],
          langCode: [{ required: true, message: "Регистр бичнэ үү", trigger: "blur"}],
          mobile: [{ required: true, message: "Холбоо барих мэдээлэл бичнэ үү", trigger: "blur"}],
          st1: [{ required: true, message: "Марк бичнэ үү", trigger: "blur"}],
          st2: [{ required: true, message: "Модел бичнэ үү", trigger: "blur"}],

          lat: [{ required: true, message: "Төрөл бичнэ үү", trigger: "blur"}],
          lng: [{ required: true, message: "Зориулалт бичнэ үү", trigger: "blur"}],

          i1: [{ validator: validateNumber, required: true, message: "Бүх жин бичнэ үү", trigger: "blur"}],
          i2: [{ validator: validateNumber, required: true, message: "Даац бичнэ үү", trigger: "blur"}],
          
          defaultAttachmentId: [{ required: true, message: "Албан бичиг хавсаргана уу", trigger: "blur"}],
          secondAttachmentId: [{ required: true, message: "Тээврийн хэрэгслийн гэрчилгээнийй хуулбар хавсаргана уу", trigger: "blur"}],
          thirdAttachmentId: [{ required: true, message: "Жолооны үнэмлэхний хуулбар хавсаргана уу", trigger: "blur"}],
          fourthAttachmentId: [{ required: true, message: "Тээврийн хэрэгслийн фото зураг хавсаргана уу", trigger: "blur"}],
          fifthAttachmentId: [{ required: true, message: "ААНБ -н гэрчилгээний хуулбар хавсаргана уу", trigger: "blur"}],
          
          dueStartDate: [{ required: true, message: "Зөвшөөрлийн эхлэх хугацаа сонгоно уу", trigger: "blur"}],
          seventhAttachmentId: [{ required: true, message: "Маршрутын зураг хавсаргана уу", trigger: "blur"}],
          taskDesc: [{ required: true, message: "Маршрутын тайлбар бичнэ үү", trigger: "blur"}],
          
        },
      },
      ebarimtObj: {
        modal: false,
        key: 0,
        id: 0,
        type: null,
        regNo: "",
        mode: 0,
        ebarimt: {},
        base64: null,
      },
    };
  },
  mounted() {
    this.imgcur = this.imglst[0];
    // this.$refs.input1.focus();

    this.isMobile = window.innerWidth <= 768;
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    if (this.reqObj.step == 3) {
      this.paymentMethodSelected({ id: 1 });
    } else if (this.reqObj.step == 4) {
      this.createTransitRequestPdf();
    }
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },
  methods: {
    checkByTaskId(){
      if(this.checkTsk.task!=null && this.checkTsk.task!=''){
        this.handleLoading({ isLoading: true });
        request({
          api: { method: "get", url: "Task/taskInheritedDetail" },
          params: { id: this.checkTsk.task },
          store: this.$store,
        }).then((rs) => {
          this.handleLoading({ isLoading: false });
          if(rs!=false){
            // this.checkTsk = rs.data.task;
            // console.log('resTd.data', rs.data);
            // rs.data.task.sixthAttachmentId
            // rs.data.task.seventhAttachmentId
            // Media/FilePreviewDetail?id
            this.handleLoading({ isLoading: true });
            request({
              api: { method: "get", url: "Media/FilePreviewDetail" },
              params: { id: rs.data.task.sixthAttachmentId },
              store: this.$store,
            }).then((f1) => {
              this.handleLoading({ isLoading: false });
              if(f1!=false){
                // console.log('f1', f1.data.file.link);
                this.checkFile1= f1.data.file.link;
                this.handleLoading({ isLoading: true });
                request({
                  api: { method: "get", url: "Media/FilePreviewDetail" },
                  params: { id: rs.data.task.seventhAttachmentId },
                  store: this.$store,
                }).then((f2) => {
                  this.handleLoading({ isLoading: false });
                  if(f2!=false){
                    // console.log('f2', f2.data.file.link);
                    this.checkFile2= f2.data.file.link;
                    this.checkFile2Rdy=true;
                  }
                });
              }
            });
          }else{
            this.checkFile2Rdy=false;
          }
        });
      }else{
        this.$message.error("Зөвшөөрлийн дугаараар мэдээлэл олдсонгүй.");
      }
    },
    fetchDataByPLate(){
      this.handleLoading({ isLoading: true });
      request({
        api: { method: "GET", url: "Penalty/GetVehicleFullInfo" },
        params: {
          plateNo: this.taskModel.task,
        },
        store: this.$store,
      }).then((res) => {
        this.handleLoading({ isLoading: false });
        if(res!=false) {
          this.isXypFetched= true;
          this.taskModel.taskEn= res.data.info.holderFirstname;
          this.taskModel.langCode= res.data.info.holderRegnum;
          this.taskModel.mobile= res.data.info.holderHandphone+' / '+res.data.info.holderHomephone;
          this.taskModel.st1= res.data.info.markName;
          this.taskModel.st2= res.data.info.modelName;
          this.taskModel.lat=res.data.info.type;
          this.taskModel.budget=25000;
          this.taskModel.isRepeat= res.data.info.holderType!="Хувь хүн";
        }
        
        //res.data.info.holderFirstname;
        //res.data.info.holderRegnum;
        //holderType
        //type: "Цистерн"
        //modelName: "EQ1110"
        //markName: "Dong Feng"
        
        console.log('res', res);
      });
    },
    ...mapActions(["handleLoading"]),
    createTransitRequestPdf() {
      this.reqObj.step = 4;

      // request({
      //   api: { method: "post", url: "EBarimt/CreateBill" },
      //   data: {
      //     id: this.reqObj.invoiceModel.invoiceId,
      //   },
      //   store: this.$store,
      // }).then((resBarimt) => {
      //   if (resBarimt != false) {
      //     this.ebarimtObj.mode = 2;
      //     this.ebarimtObj.ebarimt = resBarimt.data.ebarimt;
      //     this.ebarimtObj.modal = true;
      //     this.ebarimtObj.key++;

          //

          request({
            api: { method: "post", url: "RoadPay/CreateTransitRequestPdf" },
            data: {
              id: this.reqObj.requestId,
            },
            store: this.$store,
          }).then((res) => {
            if (res != false) {
              this.reqObj.pdfLink = res.data.link;
              // request({
              //   api: { method: "POST", url: "QR/Generate" },
              //   store: this.$store,
              //   data: { qr: resBarimt.data.ebarimt.qrData },
              // }).then((resQR) => {
              //   if (resQR != false) {
              //     this.ebarimtObj.base64 = resQR.data.base64;
              //   }
              // });
            }
          });
      //   }
      // });
    },
    checkInvoicePayed() {
      request({
        api: { method: "post", url: "qpay/checkInvoice" },
        data: {
          invoiceId: this.reqObj.invoiceModel.invoiceId,
        },
        store: this.$store,
      }).then((resQp) => {
        if (resQp != false) {
          if (resQp.data.paid) {
            this.createTransitRequestPdf();
            //this.reqObj
          } else {
            this.$message({
              type: "warning",
              message: "Төлбөр төлөгдөөгүй байна.",
            });
          }
          //this.reqObj.paymentStep.invoiceQpay = resQp.data;
        }
      });
    },
    paymentMethodSelected(item) {
      this.reqObj.paymentStep.paymentMethodSelected = item;
      if (item.id == 1) {
        request({
          api: { method: "GET", url: "qpay/GetSimpleInvoice" },
          params: {
            invoiceId: this.reqObj.invoiceModel.invoiceId,
          },
          store: this.$store,
        }).then((resQp) => {
          if (resQp != false) {
            this.reqObj.paymentStep.invoiceQpay = resQp.data;
          }
        });
      } else {
      }
    },

    showConfirm(e) {
      // console.log(e);
      this.$confirm(
        `<div style='text-align: justify'>
          <ul style='line-height: 1.2; font-weight:400'>
          <li class='mg-b-10'>1. (Замын хөдөлгөөний дүрмийн 22.1) Ачааны жин тэнхлэгт ногдох ачааллын хэмжээ нь тухайн тээврийн хэрэгслийн техникийн тодорхойлолтоор тогтоосон хэмжээнээс хэтрэхгүй байвал зохино. 
/Тухайн тээврийн хэрэгслийн тодорхойлолтыг баталгаажуулсан хэлбэрээр жолооч биедээ авч явна/</li>
<li class='mg-b-10'>Замын хөдөлгөөний дүрмийн 22.3) Суурин газар 22:00 цагаас 06:00 цагийн хороонд дуу чимээ гаргаж ачиж, буулгахыг хориглоно.</li>
<li class='mg-b-10'>3. Барилгын бүх төрлийн ширхэгийн ба нурмаг ачааг тээвэрлэх явцад зам талбай бохирдуулахгүй байх /ачиж яваа ачаа нь унахгүй, асгарахгүй, гоожихгүй, хийсэхгүй/ байх нөхцлийг хангаж хөдөлгөөнд оролцогчдод саад учруулахыг хориглоно.</li>
<li class='mg-b-10'>4. Тусгай зориулалтын хучлага ашиглан дээрх төрлийн ачааг тээвэрлэх ба хотын дотор парклаж зогсох, техникийн үйлчилгээ хийхийг хориглоно.</li>
<li class='mg-b-10'>5. Авто замын тухай хууль, замын хөдөлгөөний дүрэм болон түр зөвшөөрөл олгох журам зөрчсөн тохиолдолд зөвшөөрөл хураагдана.</li>
<li class='mg-b-10'>6. Бусдад шилжүүлэхийг хориглоно.</li>
<ul>
</div>`,
        "Санамж",
        {
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          //type: 'warning',
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          this.taskModel.isTender = true;
          // this.$message({
          //   type: 'success',
          //   message: 'Delete completed'
          // });
        })
        .catch(() => {
          this.taskModel.isTender = false;
        });
    },
    onResize() {
      this.isDesktop = window.innerWidth >= 991;
      this.isMobile = window.innerWidth <= 768;
    },
    handleNumber() {
      // if(!/^\d{4}$/.test(this.p1)){
      //     this.p1=null;
      // }
    },
    checkInputLength(event, inputRef) {
      if (inputRef == "input1") {
        if (event.target.value.length === 4) {
          this.$refs.input1.blur();
          this.isFocused1 = true;
        }
      }
    },
    doSrch() {
      let pltNo = this.p1 + this.p2 + this.p3;
      if (this.p2 == "ДК") {
        pltNo = this.p1 + this.p2;
      }
      if (
        this.p1 != null &&
        this.p2 != null &&
        this.p1.length == 4 &&
        this.p2.length == 2
      )
        this.$router.push({ name: "reg", params: { id: pltNo } });
      else alert("Улсын дугаар зөв оруулна уу");
    },

    fileUploaded(e, mode) {
      if (mode == 10) {
        this.taskModel.defaultAttachmentId = e.uploadedAttachmentId;
      } else if (mode == 20) {
        this.taskModel.secondAttachmentId = e.uploadedAttachmentId;
      } else if (mode == 30) {
        this.taskModel.thirdAttachmentId = e.uploadedAttachmentId;
      } else if (mode == 40) {
        this.taskModel.fourthAttachmentId = e.uploadedAttachmentId;
      } else if (mode == 50) {
        this.taskModel.fifthAttachmentId = e.uploadedAttachmentId;
      } else if (mode == 70) {
        this.taskModel.seventhAttachmentId = e.uploadedAttachmentId;
      }
    },
    resetForm() {
      this.$refs.myform.resetFields();
    },
    resetVehicleForm() {
      this.$refs.vehicleform.resetFields();
    },
    getInvoice() {
      request({
        api: {
          method: "post",
          url: "Payment/CreateInvoiceByTaskId",
        },
        data: {
          id: this.reqObj.requestId,
        },
        store: this.$store,
      }).then((res) => {
        console.log(res);
        if (res != false) {
          
          this.reqObj.step = 3;
          this.reqObj.invoiceModel = res.data;

          //GetSimpleInvoice
          request({
            api: { method: "GET", url: "qpay/GetSimpleInvoice" },
            params: {
              invoiceId: this.reqObj.invoiceModel.invoiceId,
            },
            store: this.$store,
          }).then((resQp) => {
            this.reqObj.paymentStep.invoiceQpay = resQp;
            this.paymentMethodSelected({ id: 1 });
            //this.reqObj.chirguulTypes = res.data.types;
          });
        }

        //this.reqObj.chirguulTypes = res.data.types
      });
    },
    fetchVehicleFormData() {
      this.handleLoading({ isLoading: true });
      request({
        api: { method: "GET", url: "Type/GetData" },
        params: {
          key: "ezam_tranzit_chirguultype",
        },
        store: this.$store,
      }).then((res) => {
        this.handleLoading({ isLoading: false });
        this.reqObj.chirguulTypes = res.data.types;
      });
      this.handleLoading({ isLoading: true });
      request({
        api: { method: "GET", url: "Task/GetInherited" },
        params: {
          id: 0,
          objectTypeId: 603,
          parentId: 0,
          projectId: 56542,
          boxId: this.reqObj.vehicleBoxId,
        },
        store: this.$store,
      }).then((res) => {
        this.handleLoading({ isLoading: false });

        this.vehicleModel = res.data.task;
      });
    },
    onSubmitBefore() {
      
          if(this.isXypFetched){
            this.$refs.myform.validate((valid) => {
        if (valid) {
            this.reqObj.step=2;
              }
      });
          }else{
            this.$message.error("Улсын дугаараар мэдээлэл олдсонгүй.");
          }
      
    },
    onSubmit() {
      // console.log('dd');
      // if (this.taskModel.locTypeId == this.taskModel.locTypeId1) {
      //   this.$message.error(
      //     "Эхлэх болон хүрэх байршил нэг цэгт байх боломжгүй."
      //   );
      //   return false;
      // }

      // if (
      //   this.taskModel.i3 == 1 &&
      //   (this.taskModel.st2 == null || this.taskModel.st2 == "")
      // ) {
      //   this.$message.error(
      //     "И-Баримт авах хэлбэр ААН сонгосон үед ААН регистр заавал оруулна уу."
      //   );
      //   return false;
      // }

      if (this.taskModel.isTender == false) {
        this.$message.error("Санамжыг уншиж танилцана уу.");
        return false;
      }

      switch(this.taskModel.budget){
        case 25000: this.taskModel.dueDate= moment(this.taskModel.dueStartDate).add(1, 'M'); break;
        case 50000: this.taskModel.dueDate= moment(this.taskModel.dueStartDate).add(2, 'M'); break;
        case 75000: this.taskModel.dueDate= moment(this.taskModel.dueStartDate).add(3, 'M'); break;
        case 100000: this.taskModel.dueDate= moment(this.taskModel.dueStartDate).add(4, 'M'); break;
        default: break;
      }

      this.$refs.vehicleform.validate((valid) => {
        if (valid) {
          this.handleLoading({ isLoading: true });
          //this.taskModel.d1 = new Date();
          request({
            api: { method: "POST", url: "Task/PostInherited" },
            data: { model: this.taskModel },
            store: this.$store,
          }).then((res) => {
            this.handleLoading({ isLoading: false });
            if (res != false) {
              //this.resetForm();
              //this.reqObj.modal = false;
              this.reqObj.requestId = res.taskId;
              this.handleLoading({ isLoading: true });
              request({
                api: { method: "get", url: "Task/taskInheritedDetail" },
                params: { id: this.reqObj.requestId },
                store: this.$store,
              }).then((resTd) => {
                this.handleLoading({ isLoading: false });
                this.reqObj.requestTaskObj = resTd.data.task;
                // this.reqObj.vehicleBoxId = resTd.data.task.b.filter(
                //   (x) => x.ot == 603
                // )[0].id;

                // this.fetchVehicleFormData();
                this.getInvoice();

                // this.reqObj.step = 3;
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    showPdfModal() {
      this.pdfModal = true;
    },
    checkRequestId() {
      let self = this;
      if (this.reqObj.typeObj.selectedType == 0) {
        self.reqObj.typeObj.modal = false;
        this.showReqModal();
      } else {
        this.handleLoading({ isLoading: true });
        request({
          api: { method: "POST", url: "user/LoginApi" },
          params: {
            user: "admin",
            password: "dar12$",
            key: "_pET4wSGckyQ0SG3WAOlHQ",
          },
          store: this.$store,
        }).then((resa) => {
          this.handleLoading({ isLoading: false });
          if (resa !== false) {
            if (
              self.reqObj.typeObj.requestId != undefined &&
              self.reqObj.typeObj.requestId != null
            ) {
              setToken(resa.token);
              this.handleLoading({ isLoading: true });
              request({
                api: { method: "post", url: "roadpay/checkRequestId" },
                data: {
                  id: self.reqObj.typeObj.requestId,
                },
                store: this.$store,
              }).then((res) => {
                this.handleLoading({ isLoading: false });
                if (res != false) {
                  self.reqObj.typeObj.modal = false;
                  self.reqObj.typeObj.parentRequestObj = res.data.task;
                  this.showReqModal();
                }
              });
            } else {
              alert("Зөвшөөрлийн дугаар оруулна уу.");
            }
          }
        });
      }
    },
    showReqModal() {
      this.handleLoading({ isLoading: true });
      request({
        api: { method: "POST", url: "user/LoginApi" },
        params: {
          user: "admin",
          password: "dar12$",
          key: "_pET4wSGckyQ0SG3WAOlHQ",
        },
        store: this.$store,
      }).then((resa) => {
        this.handleLoading({ isLoading: false });
        if (resa !== false) {
          //console.log("-2");
          setToken(resa.token);
          this.handleLoading({ isLoading: true });
          request({
            api: { method: "GET", url: "Task/GetInherited" },
            params: {
              id: 0,
              objectTypeId: 608,
              parentId: 0,
              projectId: 56720,
              boxId: 0,
            },
            store: this.$store,
          }).then((res) => {
            this.handleLoading({ isLoading: false });
            // console.log('res', res);
            if (res !== false) {
              this.taskModel = res.data.task;
              this.reqObj.modal = true;
            }
          });
        }
      });
    },
  },
};
</script>
<style>
@import "https://res.gosmart.mn/static/df/assets/css/dashforge.css";
.el-dialog__header {
  border-bottom: 1px solid rgba(72, 94, 144, 0.16);
  font-weight: 500;
}
.containerImg {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100%; /* Adjust as needed */
  padding-bottom: 20px;
  /* Adjust as needed */
}
.el-alert__description {
  font-size: 12px !important;
  line-height: 16px !important;
}
.el-alert__title {
  font-size: 12px !important;
  line-height: 14px !important;
  font-weight: 700;
}
</style>
